import 'jquery-ui/themes/base/core.css';
import 'jquery-ui/themes/base/accordion.css';
import 'jquery-ui/themes/base/autocomplete.css';
import 'jquery-ui/themes/base/button.css';
import 'jquery-ui/themes/base/checkboxradio.css';
import 'jquery-ui/themes/base/controlgroup.css';
import 'jquery-ui/themes/base/datepicker.css';
import 'jquery-ui/themes/base/dialog.css';
import 'jquery-ui/themes/base/draggable.css';
import 'jquery-ui/themes/base/menu.css';
import 'jquery-ui/themes/base/progressbar.css';
import 'jquery-ui/themes/base/resizable.css';
import 'jquery-ui/themes/base/selectable.css';
import 'jquery-ui/themes/base/selectmenu.css';
import 'jquery-ui/themes/base/sortable.css';
import 'jquery-ui/themes/base/slider.css';
import 'jquery-ui/themes/base/spinner.css';
import 'jquery-ui/themes/base/tabs.css';
import 'jquery-ui/themes/base/tooltip.css';
import 'jquery-ui/themes/base/theme.css';
