module.exports = function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div\n  class="gmf-layertree-group gmf-layertree-depth-1 {{$ctrl.group.visibilityState}}"\n>\n  <a\n    data-toggle="collapse"\n    href="#gmf-layertree-layer-group-{{::$ctrl.getGroupUid()}}"\n    aria-expanded="true"\n    class="fa gmf-layertree-expand-node fa-fw">\n  </a>\n\n  <a\n    class="fa fa-fw gmf-layertree-state"\n    href\n    ng-click="$ctrl.toggle()"\n  >' +
((__t = (require('gmf/icons/layers.svg?viewbox&height=1em'))) == null ? '' : __t) +
'</a>\n\n  <a\n    class="gmf-layertree-name"\n    href\n    ng-click="$ctrl.toggle()"\n  >\n    <span>{{$ctrl.group.title | translate}}</span>\n  </a>\n\n  <span class="gmf-layertree-right-buttons">\n    <a\n      href=""\n      ng-click="$ctrl.remove()">\n      <span class="fa fa-trash"></span>\n    </a>\n  </span>\n</div>\n\n<ul\n  id="gmf-layertree-layer-group-{{::$ctrl.getGroupUid()}}"\n  class="collapse in"\n>\n  <li\n    class="gmf-layertree-node gmf-layertree-depth-2"\n    ng-class="[dataSource.visible ? \'on\' : \'off\']"\n    ng-repeat="dataSource in $ctrl.group.dataSources"\n  >\n    <div class="gmf-layertree-leaf">\n      <a\n        href\n        ng-click="$ctrl.toggleDataSource(dataSource)"\n        class="gmf-layertree-layer-icon gmf-layertree-no-layer-icon"\n      >\n      </a>\n      <a\n        class="gmf-layertree-name"\n        href\n        ng-click="$ctrl.toggleDataSource(dataSource)"\n      >\n        <span>{{dataSource.name | translate}}</span>\n      </a>\n\n      <span class="gmf-layertree-right-buttons">\n        <a\n          href=""\n          ng-click="$ctrl.removeDataSource(dataSource)">\n          <span class="fa fa-trash"></span>\n        </a>\n      </span>\n    </div>\n  </li>\n</ul>\n';

}
return __p
}